import React, { useState, useMemo } from "react";
import "./became-partner.scss";
import Button from "@components/button";
import TextInput from "@components/text-input";
import TextArea from "@components/text-area";
import success from "./img/send.svg";
import fail from "./img/no-entry.svg";
import { cmsServer } from "@constants";

const BecamePartner = () => {
	const [data, setData] = useState({
		company: "",
		person: "",
		city: "",
		email: "",
		phone: "",
		question: ""
	});
	const [result, setResult] = useState(null);
	const [loading, setLoading] = useState(false);

	const validate = data => {
		let isValid = true;
		const {
			companyIsValid,
			personIsValid,
			cityIsValid,
			emailIsValid,
			phoneIsValid,
			question
		} = data;
		if (
			!companyIsValid ||
			!personIsValid ||
			!cityIsValid ||
			!emailIsValid ||
			!phoneIsValid ||
			!question
		) {
			isValid = false;
		}
		return isValid;
	};

	let formIsValid = useMemo(() => validate(data), [data]);

	const handleChange = (e, isValid) => {
		e.preventDefault();
		if (e && e.target) {
			setData({
				...data,
				[e.target.name]: e.target.value,
				[`${e.target.name}IsValid`]: isValid
			});
		}
	};

	const renderSuccessResult = () => (
		<div className="result-block">
			<img
				alt="Сообщение успешно отправлено"
				className="result-block__result-icon"
				src={success}
			/>
			<p>Ваше сообщение успешно отправлено.</p>
		</div>
	);

	const renderFailResult = () => (
		<div className="result-block">
			<img
				alt="Во время отправки сообщения произошла ошибка"
				className="result-block__result-icon"
				src={fail}
			/>
			<p>
				Во время отправки сообщения произошла ошибка. Попробуйте повторить
				операцию позднее.
			</p>
		</div>
	);

	const handleSubmit = async e => {
		e.preventDefault();
		setLoading(true);

		const { company, person, phone, email, question, city } = data;
		const body = {
			title: `Запрос на партнерсто от компании "${company}"`,
			message: `<p>
					Здравствуйте!
					<br />
					<br />
					Вам пришел запрос на партнерсто от компании <strong>"${company}"</strong> с сайта transkart.ru.
					<br />
					<br />
					<strong>Город:</strong> ${city}
					<br />
					<br />
					<strong>Контактное лицо:</strong> ${person}
					<br />
					<br />
					<strong>Контактный телефон:</strong> ${phone}
					<br />
					<br />
					<strong>E-mail для обратной связи:</strong> ${email}
					<br />
					<br />
					<strong>Сообщение:</strong>
					<br />
					${question}
					<br />
					<br />
					С уважением, ${person}
				</p>`
		};

		fetch(`${cmsServer}/api/email/send`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json;charset=utf-8"
			},
			body: JSON.stringify(body)
		})
			.then(res => {
				setLoading(false);
				if (res.status === 200) {
					setResult(renderSuccessResult());
				} else {
					setResult(renderFailResult());
				}
				window.scrollTo(0, 0);
			})
			.catch(err => {
				setLoading(false);
				setResult(renderFailResult());
				window.scrollTo(0, 0);
			});
	};

	return (
		<section className="full-width became-partner">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="instructions__header">Стать партнером</h2>
					</div>
				</div>
			</div>

			<div className="grid-container">
				<div className="row">
					{result ? (
						<div className="col-xs-12 col-md-offset-3 col-md-6 col-sm-offset-1 col-sm-10 became-partner__result">
							{result}
						</div>
					) : (
						<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
							<p>
								Для того чтобы стать нашим партнером, просто позвоните по номеру
								+7&nbsp;(843)&nbsp;262-80-80 или заполните данную форму:
							</p>
							<form autoComplete="off" onSubmit={handleSubmit}>
								<TextInput
									name="company"
									label="Название фирмы"
									value={data.company}
									onChange={handleChange}
									required
								/>
								<TextInput
									name="person"
									label="Контактное лицо"
									value={data.person}
									onChange={handleChange}
									required
								/>
								<TextInput
									name="city"
									label="Город"
									value={data.city}
									onChange={handleChange}
									required
								/>
								<TextInput
									name="email"
									label="Контактный e-mail"
									value={data.email}
									onChange={handleChange}
									regexp="(.+)@(.+){2,}\.(.+){2,}"
									textError="Проверьте правильность ввода email адреса"
									required
								/>
								<TextInput
									name="phone"
									label="Контактный телефон"
									value={data.phone}
									onChange={handleChange}
									required
								/>
								<TextArea
									name="question"
									label="Интересующие Вас вопросы"
									value={data.question}
									onChange={handleChange}
									required
								/>
								<Button
									className="button-became-partner-form"
									title="Отправить"
									onClick={handleSubmit}
									disabled={!formIsValid}
									loading={loading}
								/>
							</form>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};

export default BecamePartner;
